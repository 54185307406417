import {InjectionToken} from '@angular/core'

/**
 * some routes
 */
export const CUSTOMER_ROUTE_PATH = 'customers'
export const COUNSELLING_ROUTE_PATH = 'counselling'
export const MORNINGSTAR_ROUTE_PATH = 'morningstar'
export const PORTFOLIO_ROUTE_PATH = 'portfolios'
export const LOGIN_ROUTE_PATH = 'login'

/**
 * A couple of injection tokens
 */
export const FILE_READER = new InjectionToken<FileReader>('fileReader')
export const WINDOW = new InjectionToken<Window>('window')