import {Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, Observable, of, ReplaySubject} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {LOGIN_ROUTE_PATH} from '../application/data-types'

export const PORTFOLIO_MANAGER_ROLE = 'irPfAdmin'
export const USER_ROLE = 'irUser'
export const ADMIN_ROLE = 'irAdmin'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = signal<string | null>(null)

  // Possible user roles as signals
  public isIrAdmin$ = signal(false)
  public isIrUser$ = signal(false)
  public isPortfolioManager$ = signal(false)

  public currentUser$: Observable<SparbankenUser | null>
  public pCurrentUser$: BehaviorSubject<SparbankenUser | null> =
    new BehaviorSubject<SparbankenUser | null>(null)

  /**
   * Anyone can send status messages here...
   * Statuses are shown while loading.
   */
  public status$ = new ReplaySubject<string>(1)

  /**
   * Send and receive error messages.
   */
  public errors$ = new ReplaySubject<string | null>(1)

  constructor(
    private helperService: HelperService,

    private ssoService: SingleSignOnService,
    private router: Router
  ) {
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload = HelperService.GetTokenPayload(token)
    if (payload) {
      this.accessToken$.set(token)
      this.isIrAdmin$.set(payload.roles.includes(ADMIN_ROLE))
      this.isIrUser$.set(payload.roles.includes(USER_ROLE))
      this.isPortfolioManager$.set(payload.roles.includes(PORTFOLIO_MANAGER_ROLE))

      return this.helperService.getCurrentUser(environment.authServiceUrl)
        .pipe(map((user: SparbankenUser) => {
          this.pCurrentUser$.next(user)
          return false
        }))
    }
    /**
     * If we are called with invalid tokens we reset all log in data
     * we do not explicitly LOG OUT!
     */
    this.reset()
    return of(true)
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge/concat
   */
  public sso(): Observable<string> {
    return this.ssoService
      .getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.reset()
          return EMPTY
        })
      )
  }

  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Reset all values and navigates to login
    this.reset()
  }

  /**
   * Reset all admin values, and go to login. Do
   * not explicitly log out.
   */
  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.set(null)
    this.isIrAdmin$.set(false)
    this.isIrUser$.set(false)
    this.isPortfolioManager$.set(false)
    this.pCurrentUser$.next(null)
    // Go to log-in
    this.router.navigate([LOGIN_ROUTE_PATH]).then()
  }

}
